<template>
  <div id="all-events-list">
    <v-overlay :value="DeleteLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-container class="py-0">
        <v-speed-dial
          v-model="FloatingButtonOptions.fab"
          :top="FloatingButtonOptions.top"
          :bottom="FloatingButtonOptions.bottom"
          :right="FloatingButtonOptions.right"
          :left="FloatingButtonOptions.left"
          :direction="FloatingButtonOptions.direction"
          :open-on-hover="FloatingButtonOptions.hover"
          :transition="FloatingButtonOptions.transition"
          :fixed="true"
          v-if="FloatingButtonOptions.SpeedDialFlag"
        >
          <template v-slot:activator>
            <v-btn
              v-model="FloatingButtonOptions.fab"
              color="blue darken-2"
              dark
              fab
            >
              <v-icon v-if="FloatingButtonOptions.fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="FloatingButtonOptions.CreateButtonFlag"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="indigo"
                @click.prevent="addRecordPrompt = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  FloatingButtonOptions.EditButtonFlag && tableData1.length > 0
                "
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="green"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  FloatingButtonOptions.DeleteButtonFlag &&
                  tableData1.length > 0
                "
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="red"
                @click.prevent="deleteConfirmAlert"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-speed-dial>
      </v-container>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-dialog v-model="addRecordPrompt" persistent max-width="75%">
        <add-event-sub-type
          :showDialog="addRecordPrompt"
          @hideDialog="hideDialog($event)"
          v-if="addRecordPrompt"
        ></add-event-sub-type>
      </v-dialog>

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p><span class="text-danger">*</span> indicates required field</p>
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form
            ref="form2"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>Events main category</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="MainCategoryId"
                  :rules="MainCategoryIdRules"
                  :items="MainCategoryIdOptions"
                  :loading="MainCategoryIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Search
                </v-btn>
                <v-btn
                  @click.prevent="resetForm"
                  color="#00a4ef"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                  Delete
                </v-btn> -->
              <p></p>
              <json-excel
                v-if="
                  tableData1.length > 0 &&
                  tableOptions1.JsonDownloadButtonFlag == true
                "
                :name="tableOptions1.ExcelFileName"
                :data="tableData1"
                :fields="tableOptions1.JsonExcelFields"
              >
                <v-btn color="info" class="mr-4"> Download </v-btn>
              </json-excel>
            </v-col>
            <p></p>
          </v-row>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                  @click.prevent="generateReportExcel"
                  :loading="ExcelLoadingFlag"
                  target="_blank"
                  color="#ff7f50"
                >
                  Generate Report
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelFileName != '' && ExcelFileFlag == true"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                >
                  <v-icon dark> mdi-download </v-icon> Download Report
                </v-btn>
              </v-col>
            </v-row>
            <p></p>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :show-select="FloatingButtonOptions.ShowSelectFlag"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img
                    v-if="item.ProfilePic != ''"
                    width="100"
                    height="100"
                    :src="item.ProfilePic"
                  />
                </template>
                <template v-slot:item.SettingsValuesContent="{ item }">
                  <froalaView
                    v-if="item.SettingsValuesContent != ''"
                    v-model="item.SettingsValuesContent"
                  ></froalaView>
                </template>
                <template v-slot:item.FilePath="{ item }">
                  <v-tooltip bottom v-if="item.FilePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != ''"
                        :href="item.FilePath"
                        target="_blank"
                        color="#2fb65a"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-download </v-icon> Download
                      </v-btn>
                    </template>
                    <span> Download {{ item.JciFormsName }} </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="Modules.PreviewEventFlag == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="Modules.AssignPresidentFlag == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="success"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-shield-account
                      </v-icon>
                    </template>
                    <span> Assign President </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";
import AddEventSubType from "@/view/pages/erp/event-sub-type/AddEventSubType.vue";

export default {
  mixins: [common],
  components: {
    AddEventSubType,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      DeleteLoadingFlag: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: {},
      selected: [],
      search: "",

      DownloadFlag: 0,
      ExcelFileName: "",
      excel_fields: {
        "Lom Id": "LomId",
        "Zone Id": "ZoneId",
        "Lom Name": "LomName",
        "President Name": "LomPresidentFullName",
        "LOM NAME": "LomName",
        "Mobile No": "LomPresidentMobileNo",
        "Email Id": "LomPresidentEmailid",
      },

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      MainCategoryIdRules: [],
      MainCategoryId: "",
      MainCategoryIdOptions: [],
      MainCategoryIdOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    MainCategoryIdOptions: function () {
      console.log("watch MainCategoryIdOptions");
      this.LoadingFlag = false;
      this.MainCategoryIdOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events_sub_category",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getMainCategoryIdOptions();
      // this.searchForm();
    },
    hideDialog(rowData) {
      console.log("hideDialog called");
      console.log({ rowData });
      var PopupId = rowData.PopupId;
      console.log({ PopupId });
      this.closePopup(PopupId);
    },
    closePopup(PopupId) {
      console.log("closePopup called");
      console.log("PopupId=" + PopupId);
      switch (PopupId) {
        case 1:
          this.addRecordPrompt = false;
          break;
        default:
          break;
      }
      this.searchForm();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/events/edit";
        var id = tr.JciEventId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    resetForm() {
      this.$refs.form2.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        this.record = tr;
        this.recordToEdit = LomId;
        this.editPageDescription =
          "Assign 2021 LOM President for " + tr.LomName;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to modifiy";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    getMainCategoryIdOptions() {
      console.log("getMainCategoryIdOptions called");
      this.LoadingFlag = true;
      this.MainCategoryIdOptionsLoading = true;
      var selectbox1_source = "MainCategoryId";
      var selectbox1_destination = "MainCategoryIdOptions";
      var selectbox1_url = "api/jci-event-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.ExcelFileFlag = false;
        this.Modules = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-event-sub-type/lists";
        var upload = {
          UserInterface: 1,
          MainCategory: this.MainCategoryId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            console.log({ records });

            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      // var validate1 = this.$refs.form2.validate();
      var FromDate = "2021-08-04";
      var ToDate = "2021-08-05";
      var validate1 = true;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;
        this.ExcelFileFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/reports/directory-information/generate-excel";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.ExcelFileFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    deleteConfirmAlert() {
      console.log("deleteConfirmAlert called");
      var message = "";
      var selected = this.selected;
      var n1 = selected.length;
      console.log({ n1 });
      var tr = n1 > 0 ? selected[0] : [];
      console.log({ tr });
      var n2 = Object.keys(tr).length;
      console.log({ n2 });

      if (n2 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Portfolio <b> " + tr.PortfolioName + "</b>";
        htmlTxt += "<br/> Main category <b> " + tr.MainTypeName + "</b>";
        htmlTxt += "<br/> Sub category name <b> " + tr.SubTypeName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");
      console.log({ tr });

      var id = tr.SubTypeId;
      console.log({ id });

      // id = 0;
      if (id > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-event-sub-type/destroy";
        var upload = {
          UserInterface: 1,
          SubCategoryId: id,
          MainCategory: tr.MainTypeId,
          SubCategoryName: tr.SubTypeName,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.DeleteLoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.selected = [];
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "Kindlly select one setting to delete. ";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>